<template>
  <div>
    <div class="modalPopup" id="cns_product_search_modal">
      <div class="modal_header">
        <h3 class="title">
          {{
            filter_type == 1
              ? '원자재'
              : filter_type == 2
              ? '제품'
              : filter_type == 4
              ? '포장재'
              : '자원'
          }}
          검색
        </h3>

        <button class="modal_close" @click="isModalClose"></button>
      </div>
      <div class="modal_body">
        <div class="modal_search_opt">
          <my-local-selectric
            v-if="showSelectric"
            :id="'product_type_selectric'"
            :watch="search_type"
            :options="filtered_resource_option"
            @changeValue="setSearchType($event)"
            v-show="filter_type != 4"
          >
          </my-local-selectric>
          <div class="input_search">
            <input
              type="text"
              :placeholder="
                filter_type == 1
                  ? '원자재 또는 규격 검색'
                  : filter_type == 2
                  ? '제품 또는 규격 검색'
                  : filter_type == 4
                  ? '포장재 검색'
                  : '자원 검색'
              "
              :value="search_value"
              @input="typing"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
          <!-- <div
            class="input_checkbox"
            v-if="
              filter_type != 1 && filter_type != 4 && groupType != undefined
            "
          >
            <label for="checkbox_resource_search01" class="label_txt">{{
              groupType == 1
                ? '생산제품만 보기'
                : groupType == 2
                ? '세트제품만 보기'
                : ''
            }}</label>
            <label
              for="checkbox_resource_search01"
              class="chk_box"
              :class="check_only_group_product ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox_resource_search01"
              @click="
                selectedIndex = -1;
                check_only_group_product = !check_only_group_product;
              "
            />
          </div> -->
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup :class="{ material_search: filter_type == 1 }">
              <col
                v-for="(n, index) in filter_type == 1 ? 5 : 5"
                :key="index"
              />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>
                  {{
                    filter_type == 1
                      ? '원자재'
                      : filter_type == 2
                      ? '제품'
                      : filter_type == 4
                      ? '포장재'
                      : '자원'
                  }}명
                </th>
                <th>규격</th>
                <th v-if="filter_type == 1">현재고</th>
                <th>
                  {{
                    filter_type == 1 || filter_type == 4
                      ? '구매처'
                      : filter_type == 2
                      ? '매출처'
                      : '거래처'
                  }}
                </th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(resource, index) in filtered_resources"
                :key="[1, 2, 4].includes(filter_type) ? resource.id : index"
              >
                <td>{{ getResourceTypeName(resource.resource_type_id) }}</td>
                <td>{{ resource.name }}</td>
                <td>{{ resource.standard }}</td>
                <td class="text_right" v-if="filter_type == 1">
                  <b>{{ $makeComma(getMaterialStock(resource.id)) }}</b>
                  {{ findInfoFromId(units, resource.incoming_unit_id).name }}
                </td>
                <td>
                  {{
                    getCompanyName(
                      resource.company_list != undefined
                        ? resource.company_list.length > 0
                          ? resource.company_list[0].company_id
                          : -1
                        : -1,
                    )
                  }}
                </td>
                <td>
                  <div class="input_checkbox">
                    <label
                      class="chk_box"
                      :for="`checkbox${index}`"
                      :class="{
                        active: selectedResource.find(x => x.id == resource.id),
                      }"
                      @click="selectResource(resource)"
                    >
                      <i class="fa fa-check"></i>
                    </label>
                    <input type="checkbox" :id="`checkbox${index}`" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <div class="warning" v-show="error_text != ''">
          {{ error_text }}
        </div>
        <button
          type="button"
          class="btn_sub2"
          :disabled="selectedResource.length < 1"
          @click="submit()"
        >
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="isModalClose"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin, DRAG_MODAL_MIXIN],
  props: {
    filter_type: {
      type: Number,
      required: true,
    },
    pre_search_value: {
      type: String,
    },
    ignore_list: {
      type: Array,
    },
    include_list: {
      type: Array,
    },
    groupType: {
      type: Number,
    },
  },
  components: {
    MyLocalSelectric,
  },
  data() {
    return {
      check_only_group_product: false,
      error_text: '',
      selectedIndex: -1,
      search_value: '',
      search_type: null,
      showSelectric: false,
      selectedResource: [],
    };
  },
  computed: {
    ...mapGetters({
      products: 'getVisibleProduct',
      materials: 'getVisibleMaterial',
      companys: 'getVisibleCompany',
      units: 'getUnitCodes',
      resource_types: 'getResourceTypeNotSort',
      resource_options: 'getResourceTypeForSelectric',
      material_stock_list: 'getMaterialTotalStock',
      material_occupied_stock: 'getMaterialOccupiedStock',
      notNullProductControl: 'getNotNullProductControl',
    }),
    filtered_resources() {
      const Hangul = require('hangul-js');
      try {
        let filtered_list = null;
        const materials = this.lodash.clonedeep(this.materials);
        const products = this.lodash.clonedeep(this.products);
        if (this.filter_type == 1) {
          filtered_list = materials
            .filter(
              x =>
                x.resource_type_id == this.search_type ||
                this.search_type == null,
            )
            .filter(
              x =>
                x.name.includes(this.search_value) ||
                Hangul.d(x.name, true)
                  .map(x => x[0])
                  .join('')
                  .includes(this.search_value) ||
                x.standard.includes(this.search_value) ||
                Hangul.d(x.standard, true)
                  .map(x => x[0])
                  .join('')
                  .includes(this.search_value),
            );
        } else if (this.filter_type == 2) {
          filtered_list = products
            .filter(
              x =>
                x.resource_type_id == this.search_type ||
                this.search_type == null,
            )
            .filter(
              x =>
                x.name.includes(this.search_value) ||
                Hangul.d(x.name, true)
                  .map(x => x[0])
                  .join('')
                  .includes(this.search_value) ||
                x.standard.includes(this.search_value) ||
                Hangul.d(x.standard, true)
                  .map(x => x[0])
                  .join('')
                  .includes(this.search_value),
            );
        } else if (this.filter_type == 4) {
          filtered_list = materials
            .filter(
              x =>
                this.findInfoFromId(this.resource_types, x.resource_type_id)
                  .detail == 'package_material',
            )
            .filter(
              x =>
                x.name.includes(this.search_value) ||
                Hangul.d(x.name, true)
                  .map(x => x[0])
                  .join('')
                  .includes(this.search_value),
            );
        } else {
          const value = this.resource_types.find(x => x.id == this.search_type);

          if (value != undefined) {
            if (value.detail.includes('material')) {
              filtered_list = materials
                .filter(x => x.resource_type_id == this.search_type)
                .filter(
                  x =>
                    x.name.includes(this.search_value) ||
                    Hangul.d(x.name, true)
                      .map(x => x[0])
                      .join('')
                      .includes(this.search_value),
                );
            } else {
              filtered_list = products
                .filter(x => x.resource_type_id == this.search_type)
                .filter(
                  x =>
                    x.name.includes(this.search_value) ||
                    Hangul.d(x.name, true)
                      .map(x => x[0])
                      .join('')
                      .includes(this.search_value),
                );
            }
          } else {
            filtered_list = materials.concat(products).filter(
              x =>
                x.name.includes(this.search_value) ||
                Hangul.d(x.name, true)
                  .map(x => x[0])
                  .join('')
                  .includes(this.search_value),
            );
          }
        }
        if (this.ignore_list != undefined && this.ignore_list.length > 0) {
          filtered_list = filtered_list.filter(
            x =>
              this.ignore_list.find(
                y => y.id == x.id && y.resource_type_id == x.resource_type_id,
              ) == undefined,
          );
        }
        if (this.include_list != undefined && this.include_list.length > 0) {
          filtered_list = filtered_list.filter(x =>
            this.include_list.map(x => x.id).includes(x.id),
          );
        }

        if (this.groupType == undefined) {
          if (this.filter_type == 1) {
            filtered_list = filtered_list.filter(x => !x.material_group_yn);
          } else if (this.filter_type == 2) {
            filtered_list = filtered_list.filter(x => !x.product_group_yn);
          }
        } else {
          if (![1, 4].includes(this.filter_type)) {
            if (this.groupType == 1) {
              filtered_list = filtered_list.filter(
                x => !x.product_group_yn || x.product_group_type_id == 1,
              );
            } else if (this.groupType == 2) {
              filtered_list = filtered_list.filter(
                x => !x.product_group_yn || x.product_group_type_id == 2,
              );
            }
          }
        }
        let del_indexs = [];
        if (this.ignore_list != undefined && this.ignore_list.length > 0) {
          filtered_list.forEach((el, index) => {
            this.ignore_list.forEach(el2 => {
              if (el.id == el2) {
                del_indexs.push(index);
              }
            });
          });
          let toFixIndex = 0;
          if (del_indexs.length > 0) {
            del_indexs.forEach(element => {
              filtered_list.splice(element - toFixIndex, 1);
              toFixIndex = toFixIndex + 1;
            });
          }
        }
        if (this.check_only_group_product) {
          filtered_list = filtered_list.filter(z => z.product_group_yn);
        }
        return filtered_list.filter(x =>
          this.notNullProductControl.find(y => y.product_id == x.id),
        );
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    filtered_resource_option() {
      const all = { label: '전체', value: null };
      const options = [all];
      if (this.filter_type == 1) {
        return options.concat(
          this.resource_options.filter(x => x.detail.includes('material')),
        );
      } else if (this.filter_type == 2) {
        return options.concat(
          this.resource_options.filter(x => x.detail.includes('product')),
        );
      } else {
        return options.concat(this.resource_options);
      }
    },
  },
  methods: {
    selectResource(value) {
      const includeIndex = this.selectedResource.findIndex(
        x => x.id == value.id,
      );
      if (includeIndex != -1) {
        this.selectedResource.splice(includeIndex, 1);
      } else {
        this.selectedResource.push(this.lodash.clonedeep(value));
      }
    },
    isModalClose() {
      this.$emit('onclose');
    },
    getMaterialStock(id) {
      const stock_quantity = this.findInfoFromId(this.material_stock_list, id)
        .stock_quantity;
      const occupied_stock = this.findInfoFromId(
        this.material_occupied_stock,
        id,
      ).quantity;

      const calStock = this.$decimalSub(
        stock_quantity != undefined ? stock_quantity : 0,
        occupied_stock != undefined ? occupied_stock : 0,
      );
      return calStock;
    },
    getCompanyName(id) {
      let hitValue = this.companys.find(x => x.id == id);
      return hitValue != undefined ? hitValue.name : '';
    },
    getResourceTypeName(id) {
      let hitValue = this.resource_types.find(x => x.id == id);
      return hitValue != undefined ? hitValue.name : '';
    },
    typing(e) {
      this.search_value = e.target.value.trim();
      this.selectedIndex = -1;
    },
    submit() {
      if (this.selectedIndex == undefined || this.selectedIndex < 0) {
        this.error_text = `테이블의 행을 클릭하여 ${
          this.filter_type == 1 ? '원자재를' : '제품을'
        } 선택하여주십시오.`;
      }
      this.$emit('onselect', this.lodash.clonedeep(this.selectedResource));
    },
    setSearchType(arg) {
      this.search_type = arg;
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.resource_types.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원 종류');
    }
    if (this.material_stock_list.length < 1) {
      await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '자재 재고');
    }
    if (this.material_occupied_stock.length < 1) {
      await this.FETCH('FETCH_MATERIAL_OCCUPIED_STOCK', '자재 재고');
    }

    // if (this.filter_type == 1) {
    //   this.search_type = this.$store.getters.getMaterialTypeForSelectric[0].value;
    // } else if (this.filter_type == 2) {
    //   this.search_type = this.$store.getters.getProductTypeForSelectric[0].value;
    // } else {
    //   this.search_type = this.$store.getters.getResourceTypeForSelectric[0].value;
    // }

    //
    // if (this.filter_type == 1 || this.filter_type == 2) {
    //   this.search_type = null;
    // } else {
    //   this.search_type = this.$store.getters.getResourceTypeForSelectric[0].value;
    // }
    this.search_type = null;
    if (this.pre_search_value != undefined) {
      this.search_value = this.pre_search_value;
    }
    this.showSelectric = true;
  },
};
</script>

<style></style>
