<template>
  <div class="article" @submit.prevent>
    <div class="tbl_option">
      <!-- <div class="tbl_opt"> -->
      <h5>출고 등록</h5>
      <div class="search_opt">
        <label>매출처</label>
        <div class="input_search">
          <input
            type="text"
            placeholder="매출처 검색"
            :readonly="newData.company_id != null"
            :value="getCompanyInfo(newData.company_id).name"
            disabled
          />
          <button>
            <i class="fa fa-search" @click="showCompanySearch = true"></i>
          </button>
        </div>
        <div
          v-if="newData.company_id != null"
          class="input_text cns_output_date"
        >
          <label>출고일</label>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="newData.output_date"
            @change="setOutputDate($event)"
          />
        </div>
      </div>
    </div>
    <div v-if="newData.company_id != null" class="new_output_wrap">
      <div class="sub_title_wrap">
        <span>출고내역</span>
        <button
          v-if="newData.sales_products.length > 0"
          class="btn_sub1"
          @click="showBarcodeSearch = true"
        >
          {{ '제품 식별표로 출고' }}
        </button>
      </div>
      <div class="mes_tbl_wrap">
        <table
          class="mes_tbl sales_out_list_cns"
          :class="{ group_product: getChildProductsList.length > 0 }"
        >
          <colgroup>
            <col
              v-for="(n, index) in getChildProductsList.length > 0 ? 14 : 13"
              :key="index"
            />
          </colgroup>
          <thead>
            <tr>
              <th>제품명{{ systemCompany.expire_yn ? ` (유통기한)` : '' }}</th>
              <th>규격</th>
              <th>납품처</th>
              <th>제품 식별번호</th>
              <!-- <th>박스</th> -->
              <th>현재고</th>
              <th>총 주문수량</th>
              <th v-show="getChildProductsList.length > 0">
                세트 투입수량
              </th>
              <th>수량</th>
              <th>단가</th>
              <th>공급가 / 부가세</th>
              <th>부가세</th>
              <th>총 출고수량</th>
              <th>매출액</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="output in display_arr" :key="output.id">
              <td class="text_left">
                {{ findInfoFromId(products, output.product_id).name }}
                <br v-show="output.id > 0 && systemCompany.expire_yn" />
                {{
                  output.id > 0 && systemCompany.expire_yn
                    ? ` (${getExpireDate(output)}까지)`
                    : ''
                }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(products, output.product_id).standard }}
              </td>
              <td class="text_left">
                {{
                  findInfoFromId(lots, output.lot_id).company_id == null
                    ? '자체생산'
                    : findInfoFromId(
                        companys,
                        findInfoFromId(lots, output.lot_id).company_id,
                      ).name
                }}
              </td>
              <td>
                {{
                  output.curr_stock == null
                    ? '-'
                    : `P${makeProductBarcode(output)}`
                }}
              </td>
              <!-- <td v-if="output.row_span != null" :rowspan="output.row_span">
                {{ getBoxName() }}
              </td> -->
              <!-- 현재고 -->
              <td
                class="text_right"
                :class="{
                  danger:
                    output.curr_stock != null &&
                    $decimalAdd(
                      output['set_quantity'] != null
                        ? $makeNumber(output.set_quantity)
                        : 0,

                      $makeNumber(output.quantity),
                    ) > output.curr_stock,
                }"
              >
                <b>{{
                  output.curr_stock == null
                    ? '-'
                    : `${$makeComma(output.curr_stock)}`
                }}</b>
                {{
                  output.curr_stock == null
                    ? ''
                    : findInfoFromId(units, output.unit_id).name
                }}
              </td>
              <!-- 총 주문수량 -->
              <td
                class="text_right"
                v-if="output.row_span != null"
                :rowspan="output.row_span"
              >
                <b>{{
                  `${$makeComma(
                    groupOutputByProductId.find(
                      x => $makeNumber(x.product_id) == output.product_id,
                    ).sum,
                  )}`
                }}</b>
                <!-- <b>{{
                  managementOutputData.length > 0
                    ? `${$makeComma(
                        groupSalesByProductId.find(
                          x => $makeNumber(x.product_id) == output.product_id,
                        ).sum,
                      )}`
                    : ''
                }}</b> -->
                {{ findInfoFromId(units, output.unit_id).name }}
              </td>
              <td class="text_right" v-show="getChildProductsList.length > 0">
                <b>{{
                  output.set_quantity == null
                    ? '-'
                    : $makeComma(output.set_quantity)
                }}</b>
                {{
                  output.set_quantity == null
                    ? ''
                    : findInfoFromId(units, output.unit_id).name
                }}
              </td>
              <!-- 수량 -->
              <td class="not_padding">
                <input
                  type="text"
                  inputmode="decimal"
                  :value="
                    $makeComma(
                      managementOutputData.find(x => x.id == output.id)
                        .quantity,
                    )
                  "
                  @input="
                    managementOutputData.find(
                      x => x.id == output.id,
                    ).quantity = $makeNumber($event.target.value);
                    newData.sales_products.find(
                      x => x.product_id == output.product_id,
                    ).quantity = managementOutputData
                      .filter(x => x.product_id == output.product_id)
                      .map(x => $makeNumber(x.quantity))
                      .reduce((a, b) => a + b, 0);
                  "
                />
              </td>
              <!-- 단가 -->
              <td
                v-if="output.row_span != null"
                :rowspan="output.row_span"
                class="not_padding"
              >
                <input
                  id="unit_price"
                  type="text"
                  :value="
                    newData.sales_products.find(
                      x => x.product_id == output.product_id,
                    ).parent_product_id != null
                      ? '-'
                      : $makeComma(
                          newData.sales_products.find(
                            x => x.product_id == output.product_id,
                          ).cost,
                        )
                  "
                  inputmode="decimal"
                  :disabled="
                    newData.sales_products.find(
                      x => x.product_id == output.product_id,
                    ).parent_product_id != null
                  "
                  @input="
                    $inputNumberInt(
                      $event,
                      newData.sales_products.find(
                        x => x.product_id == output.product_id,
                      ),
                      'cost',
                    )
                  "
                />
              </td>
              <!-- 공급가 -->
              <td
                class="text_right"
                v-if="output.row_span != null"
                :rowspan="output.row_span"
              >
                {{
                  newData.sales_products.find(
                    x => x.product_id == output.product_id,
                  ).parent_product_id != null
                    ? '-'
                    : `₩ ${$makeComma(
                        calSupplyTax(
                          getCompanyInfo(newData.company_id).vat,
                          getProductInfo(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).product_id,
                          ).tax,
                          $makeNumber(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).quantity,
                          ),
                          $makeNumber(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).cost,
                          ),
                        ).supply,
                      )}`
                }}
              </td>
              <!-- 부가세 -->
              <td
                class="text_right"
                v-if="output.row_span != null"
                :rowspan="output.row_span"
              >
                {{
                  newData.sales_products.find(
                    x => x.product_id == output.product_id,
                  ).parent_product_id != null
                    ? '-'
                    : `₩ ${$makeComma(
                        calSupplyTax(
                          getCompanyInfo(newData.company_id).vat,
                          getProductInfo(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).product_id,
                          ).tax,
                          $makeNumber(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).quantity,
                          ),
                          $makeNumber(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).cost,
                          ),
                        ).tax,
                      )}`
                }}
              </td>
              <td
                class="text_right"
                :class="{
                  danger:
                    output.curr_stock != null &&
                    $decimalAdd(
                      output['set_quantity'] != null
                        ? $makeNumber(output.set_quantity)
                        : 0,

                      $makeNumber(
                        managementOutputData.find(x => x.id == output.id)
                          .quantity,
                      ),
                    ) > output.curr_stock,
                }"
              >
                <b>{{
                  output.curr_stock != null
                    ? $makeComma(
                        $decimalAdd(
                          output['set_quantity'] != null
                            ? output.set_quantity
                            : 0,
                          $makeNumber(
                            managementOutputData.find(x => x.id == output.id)
                              .quantity,
                          ),
                        ),
                      )
                    : $makeNumber(
                        managementOutputData.find(x => x.id == output.id)
                          .quantity,
                      )
                }}</b>
                {{
                  output.curr_stock != null
                    ? findInfoFromId(units, output.unit_id).name
                    : output.box_unit_id == null
                    ? '낱개'
                    : findInfoFromId(boxs, output.box_unit_id).name
                }}
              </td>
              <!-- 매출액 -->
              <td
                v-if="output.row_span != null"
                :rowspan="output.row_span"
                class="not_padding"
              >
                <input
                  type="text"
                  :value="
                    newData.sales_products.find(
                      x => x.product_id == output.product_id,
                    ).parent_product_id != null
                      ? '-'
                      : $makeComma(
                          calSupplyTax(
                            getCompanyInfo(newData.company_id).vat,
                            getProductInfo(
                              newData.sales_products.find(
                                x => x.product_id == output.product_id,
                              ).product_id,
                            ).tax,
                            $makeNumber(
                              newData.sales_products.find(
                                x => x.product_id == output.product_id,
                              ).quantity,
                            ),
                            $makeNumber(
                              newData.sales_products.find(
                                x => x.product_id == output.product_id,
                              ).cost,
                            ),
                          ).total -
                            $makeNumber(
                              newData.sales_products.find(
                                x => x.product_id == output.product_id,
                              ).discount,
                            ),
                        )
                  "
                  inputmode="decimal"
                  @blur="
                    $event => {
                      $event.target.value = $makeComma(
                        calSupplyTax(
                          getCompanyInfo(newData.company_id).vat,
                          getProductInfo(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).product_id,
                          ).tax,
                          $makeNumber(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).quantity,
                          ),
                          $makeNumber(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).cost,
                          ),
                        ).total -
                          $makeNumber(
                            newData.sales_products.find(
                              x => x.product_id == output.product_id,
                            ).discount,
                          ),
                      );
                    }
                  "
                  @keydown.enter="
                    $event =>
                      reCalCost(
                        newData.sales_products.find(
                          x => x.product_id == output.product_id,
                        ),
                        $event.target.value,
                      )
                  "
                  @keypress="onlyNumber($event)"
                  @input="$inputNumberIntNoTarget($event)"
                  :disabled="
                    newData.sales_products.find(
                      x => x.product_id == output.product_id,
                    ).parent_product_id != null
                  "
                />
              </td>
              <td>
                <button
                  v-show="output.curr_stock != null"
                  class="tbl_delete_btn"
                  @click="deleteOutputProduct(output)"
                >
                  delete
                </button>
              </td>
            </tr>
            <tr v-if="newData.sales_products.length > 0" class="newBtn new">
              <td
                class="text_left"
                :colspan="getChildProductsList.length > 0 ? 14 : 13"
              >
                <button
                  class="btn_tbl"
                  @click="showProductControlSearch = true"
                >
                  {{ '출고제품 선택' }}
                </button>
              </td>
            </tr>
            <tr v-show="newData.company_id != null">
              <td>
                <button @click="showProductSearch = true">
                  <img src="@/assets/images/icon/icon-floating-search.png" />
                </button>
              </td>
              <td :colspan="getChildProductsList.length > 0 ? 13 : 12"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="date_options">
        <div class="receiving_info">
          <div>
            <div class="input_text">
              <label>합계액</label>
              <input
                type="text"
                placeholder=""
                readonly
                :value="
                  newData.sales_products.length > 0
                    ? '₩ ' +
                      cnsCalTotalPrice(
                        newData.sales_products,
                        getCompanyInfo(newData.company_id).vat,
                        true,
                      ).total_cost
                    : ''
                "
              />
            </div>
            <div class="input_text">
              <label>비과세</label>
              <input
                type="text"
                placeholder=""
                readonly
                :value="
                  newData.sales_products.length > 0
                    ? '₩ ' +
                      cnsCalTotalPrice(
                        newData.sales_products,
                        getCompanyInfo(newData.company_id).vat,
                        true,
                      ).non_tax_total_cost
                    : ''
                "
              />
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>과세</label>
              <input
                type="text"
                placeholder=""
                readonly
                :value="
                  newData.sales_products.length > 0
                    ? '₩ ' +
                      cnsCalTotalPrice(
                        newData.sales_products,
                        getCompanyInfo(newData.company_id).vat,
                        true,
                      ).tax_total_cost
                    : ''
                "
              />
            </div>
            <div class="input_text">
              <label>매출액</label>
              <input
                id="sales_cost"
                type="text"
                placeholder=""
                readonly
                :value="
                  newData.sales_products.length > 0
                    ? '₩ ' +
                      cnsCalTotalPrice(
                        newData.sales_products,
                        getCompanyInfo(newData.company_id).vat,
                        true,
                      ).sales_cost
                    : ''
                "
              />
            </div>
          </div>
        </div>
        <div class="text_set">
          <div class="input_text">
            <label>비고</label>
            <textarea
              type="text"
              placeholder="출고 내용"
              v-model="newData.detail"
            ></textarea>
          </div>
        </div>
        <div class="btn_wrap">
          <button class="btn_sub2" @click="submitForm">출고등록</button>
        </div>
      </div>
    </div>
    <cns-resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :groupType="2"
      :list="filteredProductRemainControl"
      :ignore_list="newData.sales_products.map(x => x.product_id)"
      @onclose="showProductSearch = false"
      @onselect="selectProduct($event)"
    ></cns-resource-search>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushNewCompany($event)"
    ></company-search>
    <cns-product-control-search
      v-if="showProductControlSearch"
      :list="filteredProductRemainControl"
      @onclose="showProductControlSearch = false"
      @onselect="appendOutputProduct($event)"
    ></cns-product-control-search>
    <barcode-read
      v-if="showBarcodeSearch"
      :isLot="false"
      @onselect="
        $event => {
          checkBarcode($event);
          showBarcodeSearch = false;
        }
      "
      @onclose="showBarcodeSearch = false"
    >
    </barcode-read>
    <two-button-modal
      :modal_title="'출고완료'"
      :modal_content="'등록된 출고내역의 제품으로</br>출고를 진행하시겠습니까?'"
      :isModalOpen="my_isModalOpen"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        $event => {
          justInput = true;
          my_isModalOpen = false;
          submitForm($event);
        }
      "
    ></two-button-modal>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import CnsResourceSearch from '@/layouts/components/search-popup/CnsResourceSearch';
import CnsProductControlSearch from '@/layouts/components/search-popup/CnsProductControlSearch';
// import MySelectric from '@/layouts/components/MySelectric';
import BarcodeRead from '@/layouts/components/BarcodeReadModal';
import PriceMixin from '@/mixins/price';
import { mapGetters } from 'vuex';
import { yyyymmdd, groupArrayObject, getExpireDate } from '@/utils/func';
import TwoButtonModal from '@/layouts/components/TwoButtonModal.vue';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin, PriceMixin],
  components: {
    // MySelectric,
    CompanySearch,
    CnsResourceSearch,
    BarcodeRead,
    CnsProductControlSearch,
    TwoButtonModal,
  },
  data() {
    return {
      showCompanySearch: false,
      showProductControlSearch: false,
      showProductSearch: false,
      showBarcodeSearch: false,

      // modal
      my_isModalOpen: false,
      justInput: false,
    };
  },
  computed: {
    ...mapGetters({
      lots: 'getLot',
      systemCompany: 'getSystemCompany',
      box_options: 'getBoxForSelectric',
      plan_sales_type_id: 'getPlanSalesType',
      newData: 'getDirectSalesFromOutput',
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      boxs: 'getBoxsNotSorted',
      materials: 'getMaterial',
      materialTotalStock: 'getMaterialTotalStock',
      notNullProductControl: 'getNotNullProductControl',
      ProductRemainControl: 'getProductRemainControl',
      managementOutputData: 'getDirectOutputData',
      collection_options: 'getCollectionTypeForSelectricFromSalesBase',
      managementMode: 'getManagementModeFromOutputPage',
    }),
    display_arr() {
      if (this.products.length > 0) {
        let clone = this.lodash.clonedeep(this.managementOutputData);
        const obj = clone.reduce(function(rv, x) {
          (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
          return rv;
        }, {});
        let src_arr = [];
        for (let k in obj) {
          if (k != 'undefined' && k != 'null') {
            src_arr.push({
              r_part_product_list: obj[k],
              product_id: k,
            });
          }
        }
        src_arr = src_arr.filter(x => {
          const mapProduct = x.r_part_product_list;
          const foundIdxTemp = mapProduct.findIndex(
            y => this.findInfoFromId(this.products, y.product_id).name,
          );
          if (foundIdxTemp >= 0) {
            return true;
          } else return false;
        });

        let rst_arr = [];
        src_arr.map(el => {
          rst_arr.push(
            el.r_part_product_list.map(y => {
              y.product_id = el.product_id;
              y.quantity = el.quantity;
              return y;
            }),
          );
          return el;
        });
        return rst_arr.reduce((a, b) => {
          if (b.length > 0) {
            b[0].row_span = b.length;
            return a.concat(b);
          } else {
            return a;
          }
        }, []);
      } else {
        return '';
      }
    },
    isOutputBoxQuantityOverThenStock() {
      let usage_list = this.lodash.clonedeep(this.OutputBoxSumQuantity);
      if (usage_list.length < 1) {
        return { state: false, over_list: [] };
      } else {
        usage_list = usage_list.filter(x => {
          const foundMaterial = this.materialTotalStock.find(
            y => y.material_id == x.material_id,
          );
          if (
            foundMaterial == undefined ||
            foundMaterial.quantity < x.usage_quantity
          ) {
            x.usage_quantity = this.$decimalSub(
              x.usage_quantity,
              foundMaterial ? foundMaterial.quantity : 0,
            );
            return true;
          } else {
            return false;
          }
        });
        return { state: usage_list.length > 0, over_list: usage_list };
      }
    },
    OutputBoxSumQuantity() {
      const filtered_output_data_has_box_unit_id = this.lodash
        .clonedeep(this.newData.sales_products)
        .filter(x => x.box_unit_id != null);
      let package_material_usage_list = [];
      filtered_output_data_has_box_unit_id.map(x => {
        const box_unit = this.lodash
          .clonedeep(this.boxs)
          .find(y => y.id == x.box_unit_id);

        box_unit.r_box_unit_material_list.map(y => {
          const cal_usage = this.$decimalMul(y.quantity, x.quantity);
          if (package_material_usage_list.length > 0) {
            const foundMaterial = package_material_usage_list.find(
              z => z.material_id == y.material_id,
            );
            if (foundMaterial != undefined) {
              foundMaterial.usage_quantity = this.$decimalAdd(
                foundMaterial.usage_quantity,
                cal_usage,
              );
            } else {
              package_material_usage_list.push({
                material_id: y.material_id,
                usage_quantity: cal_usage,
              });
            }
          } else {
            package_material_usage_list.push({
              material_id: y.material_id,
              usage_quantity: cal_usage,
            });
          }
        });
      });
      return package_material_usage_list;
    },
    checkSameQuantity() {
      let a = this.groupSalesByProductId.map(x => x.sum);
      let b = this.groupOutputByProductId.map(x => x.sum);
      console.log(JSON.stringify(a)); //여기서 sum이 null
      console.log(JSON.stringify(b));
      if (JSON.stringify(a) == JSON.stringify(b)) {
        return true;
      } else {
        return false;
      }
    },

    groupSalesByProductId() {
      const productListFilter = this.lodash.clonedeep(
        this.newData.sales_products,
      );
      // .filter(
      //   x =>
      //     !(
      //       this.getProductInfo(x.product_id).product_group_yn &&
      //       this.getProductInfo(x.product_id).product_group_type_id == 2
      //     ),
      // );
      const arr = this.lodash.clonedeep(
        groupArrayObject(productListFilter, 'product_id'),
      );

      let list = [];
      arr.forEach(el => {
        const mapQuantity = el.element.map(x => this.$makeNumber(x.quantity));
        const sumValue = mapQuantity.reduce(
          (a, b) => this.$makeNumber(a) + this.$makeNumber(b),
        );
        const singleProductQuantity = el.element.map(x =>
          this.calTotalQuantity(x),
        );
        const singleProductSum = singleProductQuantity.reduce((a, b) =>
          this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
        );

        list.push({
          product_id: el.group_key,
          sum: this.$makeNumber(sumValue),
          sing_product_sum: this.$makeNumber(singleProductSum),
          child_product_sum: this.$decimalSub(
            this.$makeNumber(sumValue),
            this.$makeNumber(singleProductSum),
          ),
        });
      });
      return list.sort((a, b) => a.product_id - b.product_id);
    },
    groupOutputByProductId() {
      const arr = this.lodash.clonedeep(
        groupArrayObject(this.managementOutputData, 'product_id'),
      );

      let list = [];
      arr.forEach(el => {
        // let toNum = n => Number(String(n).replace(/,/gi, ','));
        const mapQuantity = el.element.map(x =>
          this.$decimalAdd(
            x['set_quantity'] != null ? this.$makeNumber(x.set_quantity) : 0,
            this.calTotalQuantity(x),
          ),
        );
        const sumValue = mapQuantity.reduce(
          (a, b) => this.$makeNumber(a) + this.$makeNumber(b),
        );

        const singleProductQuantity = el.element.map(x =>
          this.calTotalQuantity(x),
        );
        const singleProductSum = singleProductQuantity.reduce((a, b) =>
          this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
        );
        list.push({
          product_id: el.group_key,
          sum: this.$makeNumber(sumValue),
          sing_product_sum: this.$makeNumber(singleProductSum),
          child_product_sum: this.$decimalSub(
            this.$makeNumber(sumValue),
            this.$makeNumber(singleProductSum),
          ),
        });
      });
      return list.sort((a, b) => a.product_id - b.product_id);
    },
    filteredProductRemainControl() {
      return this.notNullProductControl
        .filter(
          x =>
            !this.managementOutputData.map(y => y.id).includes(x.id) &&
            this.newData.sales_products
              .map(z => z.product_id)
              .includes(x.product_id),
        )
        .map(x => {
          x.create_time = this.notNullProductControl.find(
            y => y.id == x.id,
          ).create_time;

          return x;
        });
    },
    checkTotalCostValid() {
      const calPrice = this.$makeNumber(
        this.calTotalPrice(
          this.lodash.clonedeep(this.newData.sales_products),
          this.getCompanyInfo(this.newData.company_id).vat,
          true,
        ).total_cost,
      );
      if (calPrice <= 2147483647) {
        return true;
      } else {
        return false;
      }
    },
    getChildProductsList() {
      let outputProductsSum = this.lodash
        .clonedeep(this.groupOutputByProductId)
        .filter(x => this.getProductInfo(x.product_id).product_group_yn);

      outputProductsSum = outputProductsSum.map(x => {
        return this.lodash
          .clonedeep(this.getProductInfo(x.product_id))
          .product_group_list.map(y => {
            y.product_id = y.member_product_id;
            y.quantity = this.$decimalMul(
              this.$makeNumber(y.quantity),
              this.$makeNumber(x.sum),
            );
            return y;
          });
      });

      if (outputProductsSum.length == 0) {
        return [];
      }
      outputProductsSum = outputProductsSum.reduce((a, b) => a.concat(b));

      const obj = outputProductsSum.reduce(function(rv, x) {
        (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
        return rv;
      }, {});
      let list_ = [];
      for (let k in obj) {
        list_.push({
          product_id: k,
          quantity: obj[k]
            .map(x => this.$makeNumber(x.quantity))
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
        });
      }
      return list_;
    },
  },
  methods: {
    setOutputDate(e) {
      if (e.target.value != '') {
        console.log('chk', e);
        this.newData.output_date = e.target.value;
      } else {
        this.openOneButtonModal(
          '주의',
          '출고일을 삭제할 수 없습니다.<br/> 다른 날짜를 선택해주세요.',
        );
        this.newData.output_date = e.target.value;
        e.target.value = yyyymmdd(new Date());
        if (this.newData.output_date == '') {
          this.newData.output_date = yyyymmdd(new Date());
        }
      }
    },
    getExpireDate(item) {
      const products = this.findInfoFromId(this.products, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    calTotalQuantity(output) {
      return this.$makeNumber(output.quantity);
    },
    reCalCost(row, newSalesValue) {
      const total_value =
        this.$makeNumber(newSalesValue) + this.$makeNumber(row.discount);
      console.log('total_value', total_value);
      const company_vat = this.companys.find(
        x => x.id == this.newData.company_id,
      ).vat;
      const product_tax = row.tax_id;
      let rowQuantity = this.$decimalMul(
        this.$makeNumber(row.quantity),
        row.box_unit_id != null ? this.getBoxQuantity(row.box_unit_id) : 1,
      );
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
      row.collect_value =
        this.calSupplyTax(
          this.getCompanyInfo(this.newData.company_id).vat,
          this.getProductInfo(row.product_id).tax,
          this.$makeNumber(row.quantity) * 1,
          this.$makeNumber(row.cost),
        ).total - this.$makeNumber(row.discount);
    },
    checkBarcode(arg) {
      console.log(arg);
      const foundControlId = Number(arg.substr(-5));
      console.log(this.filteredProductRemainControl);
      const foundOnOutputList = this.managementOutputData.find(
        x => x.id == foundControlId,
      );
      if (foundOnOutputList != undefined) {
        this.openOneButtonModal(
          '이미 등록 되었습니다.',
          '출고내역 리스트에 해당 제품이 이미 포함되어있습니다.',
        );
        return;
      } else if (this.filteredProductRemainControl.length < 1) {
        this.openOneButtonModal(
          '출고 가능한 제품이 없습니다.',
          '제품 재고를 다시 확인해주세요',
        );
        return;
      }
      const foundItem = this.lodash
        .clonedeep(this.filteredProductRemainControl)
        .find(x => x.id == foundControlId);
      if (foundItem != undefined) {
        this.appendOutputProduct(foundItem);
      } else {
        this.openOneButtonModal(
          '출고 불가',
          '이미 출고 되었거나, 출고받은 제품이 아닙니다.',
        );
      }
    },

    deleteOutputProduct(output) {
      let findIndex = this.managementOutputData.findIndex(
        x => x.id == output.id,
      );
      console.log(findIndex, 'findIndex');
      this.managementOutputData.splice(findIndex, 1);
      this.newData.sales_products.forEach((el, index) => {
        !this.managementOutputData.find(x => x.product_id == el.product_id)
          ? this.newData.sales_products.splice(index, 1)
          : '';
      });
      this.newData.sales_products.forEach(el => {
        el.product_id == output.product_id
          ? (el.quantity = this.managementOutputData
              .filter(x => x.product_id == output.product_id)
              .map(x => this.$makeNumber(x.quantity))
              .reduce((a, b) => a + b, 0))
          : '';
      });
    },
    appendOutputProduct(e) {
      const selectedProduct = e.map(x => ({
        box_unit_id: null,
        create_time: x.create_time,
        curr_stock: this.lodash.clonedeep(x.quantity),
        location_id: null,
        id: x.id,
        lot_id: x.lot_id,
        lot_seq_id: x.lot_seq_id,
        product_id: x.product_id,
        product_ident: x.product_ident,
        quantity: 0,
        set_quantity: null,
        store_id: x.store_id,
        unit_id: x.unit_id,
      }));
      this.managementOutputData.push(...selectedProduct);
      this.showProductControlSearch = false;
    },

    submitForm() {
      if (this.validData()) {
        this.justInput = false;
        const payload = this.lodash.clonedeep(this.newData);

        if (this.newData.collection_type_id == null) {
          payload.total_collect_value = 0;
        } else {
          payload.total_collect_value = this.newData.sales_products
            .map(x => this.$makeNumber(x.collect_value))
            .reduce((a, b) => a + b, 0);
        }
        payload.input_date = yyyymmdd(new Date());
        payload.create_time = yyyymmdd(new Date());
        let sumSupplyMoney = 0;
        let sumTaxMoney = 0;
        let sumTotalMoney = 0;
        let sumDiscountMoney = 0;
        let sumSalesMoney = 0;
        let sumTaxProductMony = 0;
        let sumTaxFreeProductMony = 0;
        payload.vat_id = this.getCompanyInfo(this.newData.company_id).vat;
        payload.sales_products.forEach(el => {
          el.quantity = this.$makeNumber(el.quantity);
          el.cost = this.$makeNumber(el.cost);
          el.discount = this.$makeNumber(el.discount);
          if (this.newData.collection_type_id == null) {
            el.collect_value = 0;
          } else {
            el.collect_value = this.$makeNumber(el.collect_value);
          }
          el.tax_id = this.getProductInfo(el.product_id).tax;
          el.supply_value = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity),
            this.$makeNumber(el.cost),
          ).supply;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity),
            this.$makeNumber(el.cost),
          ).tax;
          el.total_cost = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity),
            this.$makeNumber(el.cost),
          ).total;
          sumSupplyMoney += el.supply_value;
          sumTaxMoney += el.tax;
          sumTotalMoney += el.total_cost;
          sumDiscountMoney += el.discount;
          sumSalesMoney += el.total_cost - el.discount;
          if (this.getProductInfo(el.product_id).tax == true) {
            sumTaxProductMony += el.total_cost;
          } else {
            sumTaxFreeProductMony += el.total_cost;
          }
        });

        let productOutPayload = this.lodash
          .clonedeep(this.managementOutputData)
          .filter(x => x.curr_stock != null);
        const account = {
          output_date: payload.output_date,
          supply_value: sumSupplyMoney,
          tax: sumTaxMoney,
          total_value: sumTotalMoney,
          sales_value: sumSalesMoney,
          tax_value: sumTaxProductMony,
          tax_free_value: sumTaxFreeProductMony,
          discount: sumDiscountMoney,
          // sales_id: this.newData.id,
          detail: null,
        };

        productOutPayload.forEach(x => {
          x.quantity = this.$decimalAdd(
            x['set_quantity'] != null ? this.$makeNumber(x.set_quantity) : 0,
            this.calTotalQuantity(x),
          );
        });

        payload.account = account;
        payload.product_out_list = productOutPayload;
        payload.product_out_list.forEach(el => {
          el.quantity = this.$makeNumber(el.quantity);
        });
        payload.sales_products = payload.sales_products.map(x => {
          if (x.parent_product_id == null) {
            x.out_products = this.lodash
              .clonedeep(payload.product_out_list)
              .filter(y => y.product_id == x.product_id)
              .map(y => {
                y.quantity = this.$decimalSub(
                  y.quantity,
                  y.set_quantity != null ? y.set_quantity : 0,
                );
                return y;
              });
          } else {
            x.out_products = this.lodash
              .clonedeep(payload.product_out_list)
              .filter(y => y.product_id == x.product_id)
              .map(y => {
                y.quantity = y.set_quantity;
                return y;
              });
          }

          return x;
        });

        payload.product_out_list.forEach(el => {
          el.quantity = this.calTotalQuantity(el);
        });
        payload.box_out_list = this.lodash.clonedeep(this.OutputBoxSumQuantity);

        console.log('payloa@d', payload);
        this.showSpinner();
        this.$store
          .dispatch('INSERT_SALES_ACCOUNT_ALL', payload)
          .then(() => {
            this.$store.commit('setDirectSalesToOutput', {
              company_id: null,
              sales_type_id: 2,
              completed: false,
              delivery_date: null,
              input_date: null,
              output_date: null,
              collection_type_id: null,
              detail: '',
              sales_products: [],
              total_discount: 0,
            });
            this.$store.commit('setDirectOutputData', []);
            this.openOneButtonModal(
              '등록 성공',
              '성공적으로 출고를 등록하였습니다.',
            );
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '등록 실패',
              '출고 등록 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: this.$store.getters.getStartDateFromSales,
          end_date: this.getEndDateFromSales,
          check_only_not_completed: this.$store.getters
            .getCheckOnlyNotCompletedFromSales,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.$store.commit('setSales', []);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    validData() {
      if (this.newData.sales_products.length < 1) {
        this.openOneButtonModal(
          '등록 불가',
          '반드시 하나 이상의 주문내역을 등록하여주십시오.',
        );
        return false;
      } else if (
        this.managementOutputData == undefined ||
        this.managementOutputData == null ||
        this.managementOutputData.filter(x => x.curr_stock != null).length < 1
      ) {
        this.openOneButtonModal(
          '등록 불가',
          '반드시 하나 이상의 출고품목을 등록하여주십시오.',
        );
        return false;
      } else if (
        this.managementOutputData.filter(x => this.$makeNumber(x.quantity) == 0)
          .length > 0
      ) {
        this.openOneButtonModal(
          '등록 불가',
          '주문수량은 최소 1이상이어야 합니다.',
        );
        return false;
      } else if (!this.checkTotalCostValid) {
        this.openOneButtonModal(
          '합계액 초과',
          '1회 출고 최대 금액은<br />20억 이상 출고할 수 없습니다.',
        );
        return false;
      } else if (
        this.managementOutputData.filter(
          x =>
            this.$decimalAdd(
              x['set_quantity'] != null ? this.$makeNumber(x.set_quantity) : 0,
              this.$decimalMul(this.$makeNumber(x.quantity), 1),
            ) <= 0,
        ).length > 0
      ) {
        this.openOneButtonModal(
          '등록 불가',
          '출고수량은 최소 1이상이어야 합니다.',
        );
        return false;
      } else {
        const find = this.managementOutputData
          .filter(x => x.curr_stock != null)
          .find(
            x =>
              this.$decimalAdd(
                x['set_quantity'] != null
                  ? this.$makeNumber(x.set_quantity)
                  : 0,
                this.$decimalMul(this.$makeNumber(x.quantity), 1),
              ) > this.$makeNumber(x.curr_stock),
          );
        if (find != undefined) {
          this.openOneButtonModal(
            '등록불가',
            '출고 수량은 현재고량보다 클 수 없습니다.',
          );
          return false;
        } else if (this.isOutputBoxQuantityOverThenStock.state) {
          this.openOneButtonModal(
            '등록불가',
            `출고에 사용될 박스의 포장재 재고가 부족합니다.<br/>
          ====================================<br/>
          ${this.isOutputBoxQuantityOverThenStock.over_list
            .map(
              x =>
                this.findInfoFromId(this.materials, x.material_id).name +
                ' > ' +
                this.$makeComma(x.usage_quantity) +
                ' ' +
                this.findInfoFromId(
                  this.units,
                  this.findInfoFromId(this.materials, x.material_id)
                    .incoming_unit_id,
                ).name +
                ' 부족',
            )
            .join('<br/>')}

          `,
          );
          return false;
        } else if (!this.checkSameQuantity && !this.justInput) {
          this.my_isModalOpen = true;
          return false;
        } else return true;
      }
    },
    async selectProduct(arg) {
      const productInfo = arg.map(x => ({
        product_id: x.id,
        unit_id: x.stock_unit_id,
        box_unit_id: null,
        standard: x.standard,
        quantity: 0,
        cost: 0, //단가
        supply_value: 0, //공급가
        tax: 0, //부가세
        total_cost: 0, //합계액
        discount: '0', //단일 할인액
        tax_id: x.tax,
        collect_value: 0,
        parent_product_id: null,
      }));
      console.log(arg, 'arg???');
      this.newData.sales_products.push(...productInfo);

      this.showProductSearch = false;
      // }
    },
    getBoxName(box_unit_id) {
      let hit = this.box_options.find(x => x.value == box_unit_id);
      return hit != undefined ? hit.label : '';
    },
    getBoxQuantity(id) {
      let hit = this.boxs.find(x => x.id == id);
      return hit != undefined ? hit.quantity : 1;
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    pushNewCompany(arg) {
      this.newData.company_id = arg.id;
      this.showCompanySearch = false;
    },
    deleteProduct(sales_product, index) {
      this.newData.sales_products.splice(index, 1);

      const deleteProductInfo = this.findInfoFromId(
        this.products,
        sales_product.product_id,
      );
      if (
        deleteProductInfo.product_group_yn &&
        deleteProductInfo.product_group_type_id == 2 &&
        this.newData.sales_products.find(
          x => x.product_id == sales_product.product_id,
        ) == undefined
      ) {
        this.newData.sales_products = this.newData.sales_products.filter(
          x => x.parent_product_id != sales_product.product_id,
        );
      }

      if (this.managementOutputData.length > 0) {
        if (deleteProductInfo.product_group_yn) {
          const filteredGroupProduct = this.lodash
            .clonedeep(this.managementOutputData)
            .filter(x => x.product_id == deleteProductInfo.id);
          const findLastGroupProductId = this.managementOutputData.findIndex(
            x =>
              x.id == filteredGroupProduct[filteredGroupProduct.length - 1].id,
          );

          this.managementOutputData.splice(findLastGroupProductId, 1);
        }

        const groupProductList = this.lodash
          .clonedeep(this.managementOutputData)
          .filter(x => x.curr_stock == null);

        const notGroupProductList = this.lodash
          .clonedeep(this.managementOutputData)
          .filter(
            x =>
              x.curr_stock != null &&
              this.newData.sales_products.find(
                y => x.product_id == y.product_id,
              ) != undefined,
          );

        this.$store.commit(
          'setDirectOutputData',
          groupProductList.concat(notGroupProductList),
        );
      }
    },
    makeGroupProduct(groupProductInfo) {
      let arg = this.lodash.clonedeep(groupProductInfo);
      const filteredParentProduct = this.lodash
        .clonedeep(this.managementOutputData)
        .filter(x => x.id < 0);

      arg.create_time = null;
      arg.id = -1 - filteredParentProduct.length;
      arg.lot_id = null;
      arg.curr_stock = null;

      const findSameProductIndex = filteredParentProduct.findIndex(
        x => x.product_id == arg.product_id,
      );
      if (findSameProductIndex != -1) {
        const parentProductCnt = filteredParentProduct.filter(
          x => x.product_id == arg.product_id,
        ).length;

        this.managementOutputData.splice(
          this.$decimalAdd(findSameProductIndex, parentProductCnt),
          0,
          arg,
        );
      } else {
        this.managementOutputData.splice(filteredParentProduct.length, 0, arg);
      }
    },
    makeProductBarcode(product) {
      if (product.curr_stock != null) {
        const date = yyyymmdd(new Date(product.create_time)).replace(/-/gi, '');
        const product_control_id = product.id;
        const fix5 = ('00000' + product_control_id).substr(-5);
        product.barcode_num = date + fix5;
        return date + fix5;
      } else {
        product.barcode_num = null;
        return null;
      }
    },
    replaceOutputChildProductQuantity(output) {
      console.log('진입', output);
      //output의 product_group_yn로 부모제품인지 자식제품인지 확인
      const groupProductChk = this.getProductInfo(output.product_id);
      // output이 자식제품에 포함된 제품인지 확인
      const groupChildProductChk = this.getChildProductsList
        .map(x => this.$makeNumber(x.product_id))
        .includes(output.product_id);

      //출고내역 그룹제품 수정시 수량 재계산
      if (
        this.getChildProductsList.length > 0 &&
        (groupProductChk.product_group_yn || groupChildProductChk)
      ) {
        console.log('출고내역 재계산 진입');
        let groupChildProduct = groupProductChk.product_group_list.map(
          y => y.member_product_id,
        );
        let filteredManageOutputData = groupProductChk.product_group_yn
          ? this.managementOutputData.filter(x =>
              groupChildProduct.includes(x.product_id),
            )
          : this.managementOutputData.filter(
              x => x.product_id == output.product_id,
            );
        filteredManageOutputData = filteredManageOutputData.map(x => {
          x.curr_stock_temp = x.curr_stock;
          return x;
        });

        // 세트상품 수량 채우기
        let childProductList = groupProductChk.product_group_yn
          ? this.lodash
              .clonedeep(this.getChildProductsList)
              .filter(x =>
                groupChildProduct.includes(this.$makeNumber(x.product_id)),
              )
          : this.lodash
              .clonedeep(this.getChildProductsList)
              .filter(x => x.product_id == output.product_id);

        childProductList.forEach(x => {
          let findManageOutputData = filteredManageOutputData.filter(
            y => y.product_id == x.product_id,
          );
          findManageOutputData.forEach((y, index) => {
            if (y.curr_stock_temp > x.quantity) {
              y.set_quantity = x.quantity;
              y.curr_stock_temp = this.$decimalSub(
                this.$makeNumber(y.curr_stock_temp),
                this.$makeNumber(x.quantity),
              );
              x.quantity = 0;
            } else {
              y.set_quantity =
                findManageOutputData.length - 1 == index
                  ? x.quantity
                  : y.curr_stock_temp;
              x.quantity = this.$decimalSub(
                this.$makeNumber(x.quantity),
                this.$makeNumber(y.curr_stock_temp),
              );
              y.curr_stock_temp = 0;
            }
          });
        });
      }
    },
  },
  async created() {
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.plan_sales_type_id == undefined) {
      await this.FETCH('FETCH_SALES_TYPE', '판매 유형');
    }
    if (this.collection_options.length < 2) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }
    if (this.box_options.length < 2) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
    await this.FETCH('FETCH_NOT_NULL_PRODUCT_CONTROL');
    await this.FETCH('FETCH_PRODUCT_REMAIN_CONTROL');
    if (this.newData.input_date == null) {
      var date = new Date();
      this.newData.input_date = yyyymmdd(date);
      var date2 = new Date(date.setDate(date.getDate() + 7));
      this.newData.delivery_date = yyyymmdd(date2);
    }
    this.newData.output_date = yyyymmdd(new Date());
    this.newData.sales_type_id = 2;
  },
};
</script>

<style>
.danger b {
  color: #f98787;
}
</style>
