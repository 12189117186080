var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"modalPopup",attrs:{"id":"cns_product_search_modal"}},[_c('div',{staticClass:"modal_header"},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.filter_type == 1 ? '원자재' : _vm.filter_type == 2 ? '제품' : _vm.filter_type == 4 ? '포장재' : '자원')+" 검색 ")]),_c('button',{staticClass:"modal_close",on:{"click":_vm.isModalClose}})]),_c('div',{staticClass:"modal_body"},[_c('div',{staticClass:"modal_search_opt"},[(_vm.showSelectric)?_c('my-local-selectric',{directives:[{name:"show",rawName:"v-show",value:(_vm.filter_type != 4),expression:"filter_type != 4"}],attrs:{"id":'product_type_selectric',"watch":_vm.search_type,"options":_vm.filtered_resource_option},on:{"changeValue":function($event){return _vm.setSearchType($event)}}}):_vm._e(),_c('div',{staticClass:"input_search"},[_c('input',{attrs:{"type":"text","placeholder":_vm.filter_type == 1
                ? '원자재 또는 규격 검색'
                : _vm.filter_type == 2
                ? '제품 또는 규격 검색'
                : _vm.filter_type == 4
                ? '포장재 검색'
                : '자원 검색'},domProps:{"value":_vm.search_value},on:{"input":_vm.typing}}),_vm._m(0)])],1),_c('div',{staticClass:"mes_tbl_wrap"},[_c('table',{staticClass:"mes_tbl"},[_c('colgroup',{class:{ material_search: _vm.filter_type == 1 }},_vm._l((_vm.filter_type == 1 ? 5 : 5),function(n,index){return _c('col',{key:index})}),0),_c('thead',[_c('tr',[_c('th',[_vm._v("구분")]),_c('th',[_vm._v(" "+_vm._s(_vm.filter_type == 1 ? '원자재' : _vm.filter_type == 2 ? '제품' : _vm.filter_type == 4 ? '포장재' : '자원')+"명 ")]),_c('th',[_vm._v("규격")]),(_vm.filter_type == 1)?_c('th',[_vm._v("현재고")]):_vm._e(),_c('th',[_vm._v(" "+_vm._s(_vm.filter_type == 1 || _vm.filter_type == 4 ? '구매처' : _vm.filter_type == 2 ? '매출처' : '거래처')+" ")]),_c('th',[_vm._v("선택")])])]),_c('tbody',_vm._l((_vm.filtered_resources),function(resource,index){return _c('tr',{key:[1, 2, 4].includes(_vm.filter_type) ? resource.id : index},[_c('td',[_vm._v(_vm._s(_vm.getResourceTypeName(resource.resource_type_id)))]),_c('td',[_vm._v(_vm._s(resource.name))]),_c('td',[_vm._v(_vm._s(resource.standard))]),(_vm.filter_type == 1)?_c('td',{staticClass:"text_right"},[_c('b',[_vm._v(_vm._s(_vm.$makeComma(_vm.getMaterialStock(resource.id))))]),_vm._v(" "+_vm._s(_vm.findInfoFromId(_vm.units, resource.incoming_unit_id).name)+" ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(_vm.getCompanyName( resource.company_list != undefined ? resource.company_list.length > 0 ? resource.company_list[0].company_id : -1 : -1 ))+" ")]),_c('td',[_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"chk_box",class:{
                      active: _vm.selectedResource.find(function (x) { return x.id == resource.id; }),
                    },attrs:{"for":("checkbox" + index)},on:{"click":function($event){return _vm.selectResource(resource)}}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":("checkbox" + index)}})])])])}),0)])])]),_c('div',{staticClass:"modal_footer"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error_text != ''),expression:"error_text != ''"}],staticClass:"warning"},[_vm._v(" "+_vm._s(_vm.error_text)+" ")]),_c('button',{staticClass:"btn_sub2",attrs:{"type":"button","disabled":_vm.selectedResource.length < 1},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" 선택 ")])])]),_c('div',{staticClass:"modal_layer",on:{"click":_vm.isModalClose}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',[_c('i',{staticClass:"fa fa-search"})])}]

export { render, staticRenderFns }